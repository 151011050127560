<template>
  <div class="ml-2 mt-2">
    <h3>Vizualizer</h3>
    <div class="viz-wrapper"> 
      <div class="viz-circle" :style="`transform: scale(${instantMeter * 2});`"></div>
      <div class="viz-circle viz-over" :style="`transform: scale(${slowMeter * 2});`"></div>
    </div>
    <p>
      instantMeter: {{instantMeter}}<br/>
      slowMeter: {{slowMeter}}<br/>
      clipMeter: {{clipMeter}}<br/>
    </p>
    <button class="btn btn-primary" @click="start()">Start</button>
    <button class="btn btn-primary" @click="release(); release = null;" v-if="release">Stop</button>
  </div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("test-spectrum");
import { createSoundMeter, getUserMedia } from "@/services/mediautils";

export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      release: null,

      instantMeter: 0,
      slowMeter: 0,
      clipMeter: 0,
    };
  },
  beforeDestroy() {
    if (this.release)
      this.release();
  },
  mounted() {
    log.log("mounted");    
  },
  methods: {
    async start() {
      log.log("start");
      let stream = await getUserMedia({audio:true, video:false});
      this.release = await createSoundMeter(stream, (event) => {
        if (event.data) {
          this.instantMeter = event.data.instant.toFixed(3);
          this.slowMeter = event.data.slow.toFixed(3);
          this.clipMeter = event.data.clip;
        }
      });
    },
  }
}
</script>

<style scoped>
.viz-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  height: 50px;
  width: 50px;
}
.viz-over {
  background-color: rgb(248, 130, 130);
  z-index: -1;
}
.viz-circle {
  position: absolute;
  display: inline-block;
  height: 50px;
  width: 50px;
  background-color: rgba(248, 130, 130, 0.5);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-top: 14px;
}
</style>